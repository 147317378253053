<template>
  <div class="refuse-reason">
    <yd-cell-group>
      <yd-cell-item>
        <yd-textarea slot="right" placeholder="请输入拒绝理由" v-model="content"  maxlength="100"></yd-textarea>
      </yd-cell-item>
    </yd-cell-group>
    <div class="save-box">
      <div class="save-btn" @click.stop="refuseExamine">保存</div>
    </div>
  </div>
</template>

<script>
import {refuseExamine} from "@/services/my";

export default {
  name: "feedback",
  data() {
    return {
      pay_order_id: '',//订单id
      content: '',//拒绝理由
    }
  },
  methods: {
    getParams() {
      this.pay_order_id = this.$route.query.id ? this.$route.query.id : '';
      if (!this.pay_order_id)
        this.$router.go(-1);

    },
    /**
     * 内采商品审核拒绝
     * @returns {Promise<void>}
     */
    async refuseExamine() {
      try {
        if (this.pay_order_id) {
          let params = {
            pay_order_id: this.pay_order_id,
            content: this.content,
          };
          if (!params.content) {
            this.$dialog.toast({
              mes: '请输入拒绝理由',
            });
            return
          }
          const res = await refuseExamine(params);
          if (res.code === 0) {
            this.$dialog.toast({
              mes: res.msg,
              icon: "success"
            });
            this.$router.go(-2);
          }
        }
      } catch (e) {

      }
    },
  },
  mounted() {
    this.getParams()
  }
}
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";


.refuse-reason {
  yd-textarea {
    padding: 0.24rem 0;

  }

  .yd-textarea > textarea {
    height: 4.12rem;
    .sc(0.32rem, #333);
  }

  .yd-textarea > textarea::placeholder {
    .sc(0.32rem, @color-light-grey);
  }

  .save-box {
    .wh(100%, 0.88rem);
    padding: 0 0.24rem;
    margin-top: 0.4rem;
  }

  .save-btn {
    .wh(100%, 100%);
    background: var(--main-color);
    border-radius: 1rem;
    line-height: 0.88rem;
    .sc(0.32rem, #fff);
    text-align: center;
  }
}
</style>