var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "refuse-reason" },
    [
      _c(
        "yd-cell-group",
        [
          _c(
            "yd-cell-item",
            [
              _c("yd-textarea", {
                attrs: {
                  slot: "right",
                  placeholder: "请输入拒绝理由",
                  maxlength: "100",
                },
                slot: "right",
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "save-box" }, [
        _c(
          "div",
          {
            staticClass: "save-btn",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.refuseExamine.apply(null, arguments)
              },
            },
          },
          [_vm._v("保存")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }